<template>
	<div>
		{{ count + 1 }}
				<div class="row">
					<div class="col-lg-3">
						<div class="card" v-on:click="$router.push('/rent')">
							<img src="../assets/images/root.webp" alt="">
							<div class="card-body">
								<div class="text-center m-t-10">
									<!-- <i class="icon-earphones f-s-50 text-success"></i> -->
									<h2 class="m-t-15 m-b-0">Manual Return</h2>
									<p class="f-s-12">Borrow one of our games</p>
									<div class="btn btn-primary btn-rounded p-l-30 p-r-30 m-t-5 m-b-10">
										Rent now
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-lg-3">
						<div class="card" v-on:click="$router.push('/return')">
							<img src="../assets/images/root.webp" alt="">
							<div class="card-body">
								<div class="text-center m-t-10">
									<!-- <i class="icon-earphones f-s-50 text-success"></i> -->
									<h2 class="m-t-15 m-b-0">List Games</h2>
									<p class="f-s-12">Completed the game?</p>
									<div class="btn btn-success btn-rounded p-l-30 p-r-30 m-t-5 m-b-10">
										Return now
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-lg-3">
						<div class="card" v-on:click="$router.push('/browse')">
							<img src="../assets/images/root.webp" alt="">
							<div class="card-body">
								<div class="text-center m-t-10">
									<!-- <i class="icon-earphones f-s-50 text-success"></i> -->
									<h2 class="m-t-15 m-b-0">Add Game</h2>
									<p class="f-s-12">Look through the Library's selection</p>
									<div class="btn btn-light btn-rounded p-l-30 p-r-30 m-t-5 m-b-10">
										Browse
									</div>
								</div>
							</div>
						</div>
					</div>				
				</div>                
				<div class="row">
					<div class="col-lg-4">
						<div class="card">
							<div class="card-body bg-primary">
								<div class="text-center">
									<h2 class="m-t-15 text-white f-s-50">{{ count }}</h2><span class="text-white m-t-10 f-s-20">games</span>
								</div>
							</div>
						</div>
					</div>
					<div class="col-lg-4">
						<div class="card">
							<div class="card-body bg-success">
								<div class="text-center">
									<h2 class="m-t-15 text-white f-s-50">1250</h2><span class="text-white m-t-10 f-s-20">user</span>
								</div>
							</div>
						</div>
					</div>
					<div class="col-lg-4">
						<div class="card">
							<div class="card-body bg-warning">
								<div class="text-center">
									<h2 class="m-t-15 text-white f-s-50">1250</h2><span class="text-white m-t-10 f-s-20">user</span>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-lg-12">
						<div class="card">
							<div class="card-body">
								<h4 class="card-title">Recent Comments</h4>
								<div class="table-responsive">
									<table class="table table-striped table-bordered verticle-middle">
										<thead>
											<tr>
												
												<th scope="col">Game Name</th>
												<th scope="col">Type</th>
												<th scope="col">Time</th>
											</tr>
										</thead>
										<tbody>
											<tr v-for="(item, k) in rents" :key="k">
												<td>
													<img src="../assets/images/avatar/1.jpg" class="w-30px rounded-circle m-r-10" alt="">{{ item.name }}
												</td>
												<!-- <td>
													<span class="f-s-13">Cras sit amet nibh libero, in gravida nulla.</span>
												</td> -->
												<td>
													<label class="label label-primary">{{item.type}}</label>
												</td>
												<td>Today <span class="text-muted f-s-12 m-l-10">{{ item.time }}</span>
												</td>
												<!-- <td><i class="fa fa-circle-o text-success f-s-12 m-r-10"></i> Active</td>
												<td>
													<div class="progress h-6px">
														<div role="progressbar" class="progress-bar bg-primary w-50pc h-6px"></div>
													</div>
												</td>
												<td><span><a href="#" data-toggle="tooltip" data-placement="top" title="Edit"><i class="fa fa-pencil color-muted m-r-5"></i> </a><a href="#" data-toggle="tooltip" data-placement="top" title="Close"><i class="fa fa-close color-danger"></i></a></span>
												</td> -->
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>                
			</div>
		<!-- </div> -->
			
</template>

<script>
export default {
  data: () => ({
	   count : 5,
	   rents: [
		   { name: 'Root', type: 'Strategy', time: 'Today 12:30' },
		   { name: 'Root', type: 'Strategy', time: 'Today 12:30' },
		   { name: 'Root', type: 'Strategy', time: 'Today 12:30' },
		   { name: 'Root', type: 'Strategy', time: 'Today 12:30' },
		   { name: 'Root', type: 'Strategy', time: 'Today 12:30' },
	   ]
  }),
  mounted () {
	document.body.classList.add('v-light', 'horizontal-nav')
  },
  destroyed () {
	document.body.classList.remove('bg-light', 'login')
  },
}
</script>