<template>
  <div>
    <div class="row">
      <div class="col-lg-8 offset-lg-2">
        <div class="card">
          <div class="card-body">
            <div class="text-center m-t-10">
              <h2 class="m-t-15 m-b-0">Scan the game you wish to rent</h2>
              <p class="f-s-12">Find the barcode on the box and use it with the scanner.</p>
              <img src="https://localhost:8080/img/barcode-scan.png"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- </div> -->
</template>

<script>
import { API as AmplifyApi } from 'aws-amplify'
const apiName = 'main';

export default {
  data: () => ({
    query: "",
    games: []
  }),
  watch: {
    query: function() {
	  const vm = this;
	  const querySnap = vm.query;
	  setTimeout(() => {
		  if(querySnap != vm.query){ return; }
		  vm.fetchGames(vm.query);
	  }, 500);
    }
  },
  methods: {
	fetchGames: async function(query){
		const vm = this;
		const resp = await AmplifyApi.get(apiName, 'admin/browseBGG/search/'+encodeURIComponent(query), {});
		vm.games = resp.list;
	},
	jump: function(go){
		console.log(go);
		this.$router.push({ path: go });
	}
  },
  mounted() {
    document.body.classList.add("v-light", "horizontal-nav");
  },
  destroyed() {
    document.body.classList.remove("bg-light", "login");
  }
};
</script>